/** @jsx jsx */
import React from "react"
import { jsx } from "theme-ui"
import { Link as GatsbyLink } from "gatsby"
import Layout from "../components/layout"
import { Box, Heading, Text, Link } from "@theme-ui/components"

import Weather from "../components/weather"

const IndexPage = () => (
  <Layout>
    <Box mb={4}>
      <Box mb={4}>
        <Heading my={2} sx={{ fontSize: [6, 7] }}>
          Grüezi,
        </Heading>
        <Text as={"p"} color={"gray.7"} sx={{ fontSize: [2, 3] }}>
          I'm a front-end engineer based in Seattle.
        </Text>
        <Text as={"p"} color={"gray.7"} sx={{ fontSize: [2, 3] }}>
          Currently, I'm a senior front-end engineer at{" "}
          <Link href="https://www.apple.com/">Apple</Link>.
        </Text>
        <Text as={"p"} color={"gray.7"} sx={{ fontSize: [2, 3] }}>
          Before that I worked at {" "}
          <Link href="https://www.amazon.com/">Amazon</Link>. And before that I helped the team at{" "}
          <Link href="https://www.scott-sports.com/">SCOTT Sports</Link>{" "}
          bringing digital marketing to the next level. And before that, I crafted
          beautiful digital experiences at{" "}
          <Link href="https://www.rado.com/">Rado</Link>.
        </Text>
      </Box>
      <Box my={2}>
        <Link variant={"primary"} href="mailto:joe.jemmely@gmail.com">
          Contact
        </Link>
        <Link variant={"primary"} as={GatsbyLink} to="/resume">
          Resume
        </Link>
      </Box>
    </Box>
  </Layout>
)

export default IndexPage
